<template>
  <div class="more-info">
    <div class="cont" v-show="!control.gridShow">
      <van-cell name="所属网格" title="所属网格" :border="false" :value="show.gridName||'请选择'" @click="gridsFn" placeholder="请选择" is-link/>
      <!--    <van-cell title="所属网格" :border="false">-->
      <!--      <span style="color: #387FF5">{{ show.gridName }}</span>-->
      <!--    </van-cell>-->
      <!--    <van-grid :border="false" :column-num="4">-->
      <!--      <van-grid-item v-for="item in selectList.gridsList" :key="item.value">-->
      <!--        <van-tag size="large" @click="gridsFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.gridName}"-->
      <!--          :text-color="item.label == show.gridName?'#387FF5':'#000'">{{ item.label }}-->
      <!--        </van-tag>-->
      <!--      </van-grid-item>-->
      <!--    </van-grid>-->
      <div class="divider"></div>

      <van-cell title="证件类型" :border="false">
        <span style="color: #387FF5">{{ show.idNumberType }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.idNumberTypeList" :key="item.value">
          <van-tag size="large" @click="idNumberTypeFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.idNumberType}"
                   :text-color="item.label == show.idNumberType?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <div class="divider"></div>
      <van-cell title="出生迁入" :border="false">
      </van-cell>
      <div class="dateBar" style="height: 2.6rem;" >
        <van-row>
          <van-col span="6">出生日期：</van-col>
          <van-col span="6" @click="control.beginBirthday = !control.beginBirthday">
            <span>{{ dataForm.beginBirthday == '' ? '最早' : dataForm.beginBirthday }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endBirthday = !control.endBirthday">
            <span>{{ dataForm.endBirthday == '' ? '至今' : dataForm.endBirthday }}</span></van-col>
          <van-col span="2" @click="birthdayReset"><span>重置</span></van-col>
          <van-col span="3" @click="birthdayTimeclick"><span>确定</span></van-col>
        </van-row>
        <van-row>
          <van-col span="6">迁入日期：</van-col>
          <van-col span="6" @click="control.beginMoveInDate = !control.beginMoveInDate ">
            <span>{{ dataForm.beginMoveInDate == '' ? '最早' : dataForm.beginMoveInDate }}</span></van-col>
          <van-col span="1"><span>-</span></van-col>
          <van-col span="6" @click="control.endMoveInDate = !control.endMoveInDate ">
            <span>{{ dataForm.endMoveInDate == '' ? '至今' : dataForm.endMoveInDate }}</span></van-col>
          <van-col span="2" @click="moveInReset"><span>重置</span></van-col>
          <van-col span="3" @click="moveIntimeclick"><span>确定</span></van-col>
        </van-row>
      </div>

      <van-popup v-model="control.beginBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.beginBirthday = false" @confirm="beginBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endBirthday" position="bottom" >
        <van-datetime-picker @cancel="control.endBirthday = false" @confirm="endBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.beginMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.beginMoveInDate = false" @confirm="beginMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endMoveInDate" position="bottom" >
        <van-datetime-picker @cancel="control.endMoveInDate = false" @confirm="endMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <!-- <van-cell title="户籍性质" :border="false">
        <span style="color: #387FF5">{{ show.registryType }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.registryTypeList" :key="item.value">
          <van-tag size="large" @click="registryFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.registryType}"
            :text-color="item.label == show.registryType?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid> -->
      <div class="divider"></div>

      <van-cell name="民族" title="民族" :border="false" :value="show.nation||'请选择'" @click="control.nation = true" placeholder="请选择民族" is-link/>
      <!-- <van-popup v-model="" position="bottom">
        <van-picker
          show-toolbar
          value-key="label"
          :columns="selectList.nationList"
          @confirm="nationConfirm"
          @cancel="control.nation = false"
        />
      </van-popup> -->
      <div class="divider"></div>

      <van-cell title="有无房屋" :border="false">
        <span style="color: #387FF5">{{ show.haveHouse }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.haveHouseList" :key="item.value">
          <van-tag size="large" @click="haveHouseFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.haveHouse}"
                   :text-color="item.label == show.haveHouse?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <div class="divider"></div>

      <van-cell title="年龄" :border="false" />
      <div style="margin:0 15px 12.5px">
        <input v-model="dataForm.beginAge" placeholder="请输入" class="ageInput">
        <div style="height:1px;width:40px;background-color:#9A9A9A;display:inline-block;margin:0 15px 5px"></div>
        <input v-model="dataForm.endAge" placeholder="请输入" class="ageInput">
      </div>

      <div class="divider"></div>

      <van-cell title="性别" :border="false">
        <span style="color: #387FF5">{{ show.sex }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.sexList" :key="item.value">
          <van-tag size="large" @click="sexFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.sex}"
                   :text-color="item.label == show.sex?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <div class="divider"></div>

      <van-cell title="身份证校验" :border="false">
        <span style="color: #387FF5">{{ show.rightIdNumber }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.rightIdNumberList" :key="item.value">
          <van-tag size="large" @click="rightIdNumberFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.rightIdNumber}"
                   :text-color="item.label == show.rightIdNumber?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid>
      <div class="divider"></div>

      <van-cell name="与户主关系" title="与户主关系" :border="false" :value="show.relationship||'请选择'" @click="control.relationship = true" placeholder="请选择与户主关系" is-link/>
      <van-popup v-model="control.relationship" position="bottom">
        <van-picker
            show-toolbar
            value-key="label"
            :columns="selectList.relationshipList"
            @confirm="relationshipfirm"
            @cancel="control.relationship = false"
        />
      </van-popup>
      <div class="divider"></div>

      <!-- <van-cell title="仅显示待核查" :border="false">
        <span style="color: #387FF5">{{ show.isNotChecked }}</span>
      </van-cell>
      <van-grid :border="false" :column-num="4">
        <van-grid-item v-for="item in selectList.isNotCheckedList" :key="item.value">
          <van-tag size="large" @click="isNotCheckedFn(item)" color="#F8F8F8" :class="{'border-selected': item.label == show.isNotChecked}"
                   :text-color="item.label == show.isNotChecked?'#387FF5':'#000'">{{ item.label }}
          </van-tag>
        </van-grid-item>
      </van-grid> -->
      <div class="select-button">
        <van-row gutter="20">
          <van-col :span="8"><van-button color="#ccc" block @click="reset">重置</van-button></van-col>
          <van-col :span="16"><van-button type="info" block @click="comfirm">确认</van-button></van-col>
        </van-row>
      </div>
    </div>
    <nation-select v-if="control.nation" @setNation="setNation"></nation-select>

    <grid-select v-show="control.gridShow" :list="selectList.gridsList" @selectLabel="selectGrid" ref="gridSelect"></grid-select>
  </div>
</template>

<script>
import {getVirtualDict} from "@/utils/common"
import NationSelect from '@/components/nation/nation'
import GridSelect from './grid-select.vue'
export default {
  components: {
    NationSelect,
    GridSelect
  },
  data () {
    return {
      defaultDate: new Date(1900, 0, 1),
      control: {
        nation: false,
        relationship: false,
        beginBirthday: false,
        endBirthday: false,
        beginMoveInDate: false,
        endMoveInDate: false,
        gridShow: false
      },
      selectList: {
        gridsList: [],
        idNumberTypeList: [],
        registryTypeList: [],
        nationList: [],
        haveHouseList: [{
          label: '有',
          value: '1',
        }, {
          label: '无',
          value: '0',
        }],
        sexList: [{
          label: '男',
          value: '1',
        }, {
          label: '女',
          value: '2',
        }, {
          label: '所有',
          value: '',
        }],
        rightIdNumberList: [{
          label: '错误身份证',
          value: "0",
        }, {
          label: '正确身份证',
          value: "1",
        }],
        relationshipList: [],
        isNotCheckedList: [{
          label: '是',
          value: "1",
        }, {
          label: '否',
          value: "0",
        }]
      },
      show: {
        gridName: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        isNotChecked: ''
      },
      dataForm: {
        gridId: '',
        miniGridId: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        isNotChecked: '',
        beginAge: '',
        endAge: '',
        beginBirthday: "",
        endBirthday: "",
        beginMoveInDate: "",
        endMoveInDate: ""
      }
    }
  },
  created () {
    this.control.gridShow = false
    this.getGridList()
    // this.getRegistryType()
    getVirtualDict ("idNumberType", (virtualDictList) => {
      this.selectList.idNumberTypeList = virtualDictList
    })
    this.$http({
      url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({data}) => {
      if (data.code == 0) {
        data.relationships.map((r) => {
          r.value = r.value + ""
        })
        this.selectList.relationshipList = data.relationships
      }
    })
  },
  methods: {
    setNation(value) {
      if (value) {
        this.dataForm.nation = value.value
        this.show.nation = value.label
      }
      this.control.nation = false
    },
    getGridList () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/grid/info/gridAndMini`),
        method: 'get',
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectList.gridsList = data.treeVOS
        }
      })
    },
    // getGridList () {
    //   this.$http({
    //     url: this.$http.adornUrl(`/wxapp/grid/info/listByUser`),
    //     method: 'get',
    //     params: this.$http.adornParams({
    //       community: this.$globalData.userInfo.orgId
    //     })
    //   }).then(({data}) => {
    //     if (data.code == 0) {
    //       this.selectList.gridsList = data.grids
    //     }
    //   })
    // },
    getRegistryType () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          this.selectList.registryTypeList = data.registryTypes
        }
      })
    },
    // 网格选择
    selectGrid (e) {
      this.dataForm.gridId = ''
      this.dataForm.miniGridId = ''
      const {selectList, labelCondition, depth} = e
      if (depth == 1) {
        this.dataForm.gridId = selectList
      } else {
        this.dataForm.miniGridId = selectList
      }
      this.show.gridName = labelCondition ? labelCondition[0].label : ''
      this.control.gridShow = false
    },
    gridsFn() {
      this.control.gridShow = true
      // this.show.gridName = n.label
      // this.dataForm.gridId = n.value
    },
    idNumberTypeFn (n) {
      this.show.idNumberType = n.label
      this.dataForm.idNumberType = n.value
    },
    registryFn(n) {
      this.show.registryType = n.label
      this.dataForm.registryType = n.value
    },
    relationshipfirm (n) {
      this.control.relationship = false
      this.show.relationship = n.label
      this.dataForm.relationship = n.value
    },
    sexFn(n) {
      this.show.sex = n.label
      this.dataForm.sex = n.value
    },
    haveHouseFn(n) {
      this.show.haveHouse = n.label
      this.dataForm.haveHouse = n.value
    },
    rightIdNumberFn(n) {
      this.show.rightIdNumber = n.label
      this.dataForm.rightIdNumber = n.value
    },
    isNotCheckedFn (n) {
      this.show.isNotChecked = n.label
      this.dataForm.isNotChecked = n.value
    },
    birthdayReset: function (e) {
      that.dataForm.beginBirthday = '';
      that.dataForm.endBirthday = '';
    },
    //出生日期时间筛选中点击确定
    birthdayTimeclick: function (e) {
      this.onPullDownRefresh()
    },
    //迁入时间重置按钮点击
    moveInReset: function (e) {
      that.dataForm.beginMoveInDate = '';
      that.dataForm.endMoveInDate = '';
    },
    //嵌入时间筛选中点击确定
    moveIntimeclick: function (e) {
      that.onPullDownRefresh()
    },
    beginBirthdayChange(value) {
      let d = new Date(this.dataForm.endBirthday)
      if (this.dataForm.endBirthday != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginBirthday = formatterDate(value)
      this.control.beginBirthday = false

    },
    endBirthdayChange(value) {
      let d = new Date(this.dataForm.beginBirthday)
      if (this.dataForm.beginBirthday != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endBirthday = formatterDate(value)
      this.control.endBirthday = false
    },
    beginMoveInDateChange(value) {
      let d = new Date(this.dataForm.endMoveInDate)
      if (this.dataForm.endMoveInDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginMoveInDate = formatterDate(value)
      this.control.beginMoveInDate = false
    },
    endMoveInDateChange(value) {
      let d = new Date(this.dataForm.beginMoveInDate)
      if (this.dataForm.beginMoveInDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endMoveInDate = formatterDate(value)
      this.control.endMoveInDate = false
    },
    reset () {
      this.show = {
        gridName: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        isNotChecked: ''
      },
      this.dataForm = {
        gridId: '',
        miniGridId: '',
        idNumberType: '',
        registryType: '',
        nation: '',
        haveHouse: '',
        sex: '',
        rightIdNumber: '',
        relationship: '',
        isNotChecked: '',
        beginAge: '',
        endAge: '',
        beginBirthday: "",
        endBirthday: "",
        beginMoveInDate: "",
        endMoveInDate: ""
      }
    },
    comfirm () {
      this.$emit('selectMore', this.dataForm)
    }
  }
}
</script>
<style scoped>
.more-info {
  padding-bottom: 168px;
  background-color: #fff;
}
.more-info >>> .van-grid {
  padding: 0 20px 10px;
}
.more-info >>> .van-grid-item__content {
  padding: 10px;
}
.more-info >>> .van-tag {
  width: 100%;
  justify-content: center;
  padding: 20px 0;
  font-size: 26px;
}
.border-selected {
  border: 1px solid #387FF5;
}
.select-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
}
.ageInput {
  width: 260px;
  height: 60px;
  border: none;
  background-color: #F8F8F8;
  padding: 0 85px;
  position: relative;
}
.divider {
  height: 1px;
  background-color: #F4F4F4;
  margin: 5px 30px;
}
</style>
