<template>
  <div class="list-info" id="watermark">
    <div class="addBtn" @click="addres" v-show="!control.communityLabelCascader&&!control.moreShow">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <van-popup v-model="control.liveWhichCascader" position="bottom"
               @click-overlay="overlay">
      <van-cascader
          title="请选择"
          value="value"
          :field-names="{ text: 'label', value: 'value', children: 'children' }"
          :options="selectList.liveWhichTree"
          active-color="#1989fa"
          @close="liveWhichClose"
          @change="liveWhichFinish"
      />
    </van-popup>
    <!--<van-popup v-model="control.communityLabelCascader" position="bottom"
               @click-overlay="overlay">
      <van-cascader
          title="请选择"
          value="value"
          :field-names="{ text: 'label', value: 'value', children: 'children' }"
          :options="selectList.userCommunityLabelTree"
          active-color="#1989fa"
          @close="communityLabelCascaderClose"
          @change="communityLabelCascaderChange"
      />
    </van-popup> -->
    <van-popup v-model="control.showRegistryType" position="bottom"  @click-overlay="overlay">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="selectList.registryTypeList"
          value-key="label"
          @confirm="onConfirmReg"
          @cancel="onCancelReg"
      />
    </van-popup>
    <van-popup v-model="control.area" position="bottom"  @click-overlay="overlay">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="areaList"
          value-key="label"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
    <top-bar :title="'居民管理'" :left="true"></top-bar>
    <!-- <div class="search"> -->
        <van-search
          v-model="dataForm.searchValue"
          placeholder="输入名字、有效证件号或者手机号"
          input-align="left"
          @search="searchConfirm"
          show-action
          shape="round">
          <template #action>
            <div @click="searchConfirm">搜索</div>
          </template>
        </van-search>
      <!-- <input v-model="dataForm.searchValue" type="text" placeholder="输入名字、有效证件号或者手机号" class="search-content">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="searchCancel">
      <div class="search-btn" @click="searchConfirm">搜索</div> -->
    <!-- </div> -->
    <div>
      <div class="filterBar">
        <van-row>
          <van-col span="5">
            <p @click="control.liveWhichCascader = !control.liveWhichCascader" :style="{color:control.liveWhichCascader?'#387FF5': '#666666' }">
              居住信息
              <img :src="require(`@/assets/img/${control.liveWhichCascader?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col>
          <van-col span="5">
            <p @click="control.moreShow=false, control.communityLabelCascader = !control.communityLabelCascader" :style="{color:control.communityLabelCascader?'#387FF5': '#666666' }">
              居民标签
              <img :src="require(`@/assets/img/${control.communityLabelCascader?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col>
          <!-- <van-col span="5">
            <p @click="control.showDate = !control.showDate" :style="{color:control.showDate?'#387FF5': '#666666' } ">
              出生迁入
              <img :src="require(`@/assets/img/${control.showDate?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col> -->
          <van-col span="5">
            <p @click="control.showRegistryType = !control.showRegistryType" :style="{color:control.showRegistryType?'#387FF5': '#666666' } ">{{ show.registryType }}
              <img :src="require(`@/assets/img/${control.showRegistryType?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col>
          <van-col span="5">
            <p @click="control.area = !control.area" :style="{color:control.area?'#387FF5': '#666666' } ">{{ show.nativePlace }}
              <img :src="require(`@/assets/img/${control.area?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col>
          <van-col span="4">
            <p @click="control.communityLabelCascader=false, control.moreShow = !control.moreShow" :style="{color:control.moreShow?'#387FF5': '#666666' } ">更多
              <img :src="require(`@/assets/img/${control.moreShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
            </p>
          </van-col>
        </van-row>
      </div>
      <res-label v-show="control.communityLabelCascader" :list="selectList.userCommunityLabelTree" @selectLabel="selectLabel" ref="resLabel"></res-label>

      <select-more v-show="control.moreShow" @selectMore="selectMore" ref="selectMore"></select-more>

      <div class="dateBar" style="height: 2.6rem;" v-if='control.showDate'>
        <van-row>
          <van-col span="6">出生日期：</van-col>
          <van-col span="5" @click="control.beginBirthday = !control.beginBirthday">
            <span>{{ dataForm.beginBirthday == '' ? '最早' : dataForm.beginBirthday }}</span></van-col>
          <van-col span="2"><span>-</span></van-col>
          <van-col span="5" @click="control.endBirthday = !control.endBirthday">
            <span>{{ dataForm.endBirthday == '' ? '至今' : dataForm.endBirthday }}</span></van-col>
          <van-col span="3" @click="birthdayReset"><span>重置</span></van-col>
          <van-col span="3" @click="birthdayTimeclick"><span>确定</span></van-col>
        </van-row>
        <van-row>
          <van-col span="6">迁入日期：</van-col>
          <van-col span="5" @click="control.beginMoveInDate = !control.beginMoveInDate ">
            <span>{{ dataForm.beginMoveInDate == '' ? '最早' : dataForm.beginMoveInDate }}</span></van-col>
          <van-col span="2"><span>-</span></van-col>
          <van-col span="5" @click="control.endMoveInDate = !control.endMoveInDate ">
            <span>{{ dataForm.endMoveInDate == '' ? '至今' : dataForm.endMoveInDate }}</span></van-col>
          <van-col span="3" @click="moveInReset"><span>重置</span></van-col>
          <van-col span="3" @click="moveIntimeclick"><span>确定</span></van-col>
        </van-row>
      </div>
      <van-popup v-model="control.beginBirthday" position="bottom" >
        <van-datetime-picker @confirm="beginBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endBirthday" position="bottom" >
        <van-datetime-picker @confirm="endBirthdayChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.beginMoveInDate" position="bottom" >
        <van-datetime-picker @confirm="beginMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>
      <van-popup v-model="control.endMoveInDate" position="bottom" >
        <van-datetime-picker @confirm="endMoveInDateChange" type="date" title="选择年月日" :min-date="defaultDate" :max-date="new Date()"/>
      </van-popup>

      <!-- <van-popup v-model="control.moreShow" position="right" :style="{ width: '90%', height: '100%'}" @open="moreClose">
        <div style="margin-bottom: 50px;">
          <van-cell title="所属网格">
            <span style="color: #FE5E5E">{{ show.gridName }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="value in selectList.gridsList" :key="value.value">
              <van-tag size="large" @click="gridsFn(value)" :color="value.label === show.gridName? '#ffe1e1':'#f0f2f5'"
                       :text-color="value.label == show.gridName?'#FE5E5E':'#000'">{{ value.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>
          <van-cell title="年龄">
            <span style="color: #FE5E5E">{{ show.age }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="item in selectList.ageList" :key="item.value">
              <van-tag size="large" @click="ageFn(item)"
                       :color="item.label == show.age? '#ffe1e1':'#f0f2f5'"
                       :text-color="item.label == show.age?'#FE5E5E':'#000'">{{ item.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>
          <van-cell title="性别">
            <span style="color: #FE5E5E">{{ show.sex }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="item in selectList.sexList" :key="item.value">
              <van-tag size="large" @click="sexFn(item)"
                       :color="item.label == show.sex? '#ffe1e1':'#f0f2f5'"
                       :text-color="item.label == show.sex?'#FE5E5E':'#000'">{{ item.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>

          <van-cell title="户籍性质">
            <span style="color: #FE5E5E">{{ show.registryType }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="value in selectList.registryTypeList" :key="value.value">
              <van-tag size="large" @click="registryFn(value)"
                       :color="value.label === show.registryType? '#ffe1e1':'#f0f2f5'"
                       :text-color="value.label == show.registryType?'#FE5E5E':'#000'">{{ value.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>
          <van-cell title="入住情况">
            <span style="color: #FE5E5E">{{ show.haveHouse }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="item in selectList.haveHouseList" :key="item.value">
              <van-tag size="large" @click="haveHouseFn(item)"
                       :color="item.label == show.haveHouse? '#ffe1e1':'#f0f2f5'"
                       :text-color="item.label == show.haveHouse?'#FE5E5E':'#000'">{{ item.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>
          <van-cell title="身份证校验">
            <span style="color: #FE5E5E">{{ show.rightIdNumber }}</span>
          </van-cell>
          <van-grid :border="false" :column-num="3">
            <van-grid-item v-for="item in selectList.rightIdNumberList" :key="item.value">
              <van-tag size="large" @click="rightIdNumberFn(item)"
                       :color="item.label == show.rightIdNumber? '#ffe1e1':'#f0f2f5'"
                       :text-color="item.label == show.rightIdNumber?'#FE5E5E':'#000'">{{ item.label }}
              </van-tag>
            </van-grid-item>
          </van-grid>
        </div>
        <van-row>
          <van-col span="12">
            <van-button block @click="moreCancel">重置</van-button>
          </van-col>
          <van-col span="12">
            <van-button type="danger" block @click="moreConfirm">确定</van-button>
          </van-col>
        </van-row>
      </van-popup> -->
    </div>
<!--    <van-pull-refresh v-model="control.pullLoading" @refresh="onPullDownRefresh">-->
      <div class="cont" v-show="!control.communityLabelCascader&&!control.moreShow">
        <van-list v-model="loading" :finished="control.haveMore" finished-text="没有更多了" @load="getDataList" v-if="userResList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalNum}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in userResList" :key="item.id" @click="info(item.id)">
            <div class="title">
              <div class="title-left">
                {{item.communityNames||item.communityName}}
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content3">
              <div  class="content-img">
                <img style="border-radius: 50%" v-real-img="item.headImg ? getImg(item.headImg) : require(`@/assets/img/${item.sex == 1 ? 'headImgMan.png' : 'headImgWoman.png'}`)" :src="require(`@/assets/img/${item.sex == 1 ? 'headImgMan.png' : 'headImgWoman.png'}`)">
                <p class="content-imgBottom">{{item.registryTypeName || '未知'}}</p>
              </div>
              <div class="content-text">
                <div class="top-text">
                  {{item.name || '未知'}}
                  <span v-for="item in item.labelList"  :key="item" class="top-tag">{{ item }}</span>
                </div>
                <div class="center-text2" v-if="item.age && item.sexName && item.mobile">{{item.age + '/' + item.sexName}} {{Desensitization(item.mobile) || '未知'}}</div>
                <div class="center-text2" v-else>{{ (item.age || '') + item.sexName}} {{Desensitization(item.mobile) || '未知'}}</div>
                <div class="center-text2">{{Desensitization(item.idNumber) || '未知'}}</div>
                  <div class="bottom-btns">
                    <button v-if="item.isVisit" class="btn" style="color: #387FF5;background-color:#EBF2FE;" @click.stop="petition(item.id, item.name)">添加上访</button>
                    <button v-if="item.isPsychosis" class="btn" style="color: #FFA93A;background-color:#FFF6EB;" @click.stop="cause(item.id, item.name)">添加肇事</button>
                    <button class="btn" style="color: #387FF5;background-color:#EBF2FE;" @click.stop="goVisit(item)">走访</button>
                    <button class="btn" style="color: #ED4747;background-color:#FDECEC;" @click.stop="getCommunity(item.id)">迁出</button>
                  </div>
              </div>
              <div>
                <van-icon name="arrow" color="#666" class="bottom-icon"/>
                <div style="height: 1rem;"></div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty description="没有数据哦"  v-if="userResList.length < 1"/>
      </div>
      <van-popup v-model="control.community" position="bottom"  @click-overlay="overlay">
        <van-picker
            title="请选择迁出社区"
            show-toolbar
            :columns="communityList"
            value-key="label"
            @confirm="onConfirmCommunity"
            @cancel="control.community=false"
        />
      </van-popup>
<!--    </van-pull-refresh>-->
  </div>

</template>

<script>
import {formatterDate} from "@/utils/utils";
import {listComRightLabel} from "@/utils/common";
import topBar from '@/components/topBar/topBar'
import {getImageStream} from '@/utils/index'
import {mobile} from '@/utils/utils'
import {idNumber, desensitization} from '@/utils/utils'
// import dd from 'gdt-jsapi'
import callPhone from "gdt-jsapi/callPhone";
import ResLabel from './res-label.vue'
import SelectMore from './select-more'
var that

export default {
  name: 'userResIndex',
  components: {
    topBar,
    ResLabel,
    SelectMore
  },
  data() {
    return {
      userId: '',
      communityList: [],
      userResList: [],
      dataForm: {
        page: 0,
        limit: this.$globalData.limit,
        liveWhich: "",
        gridId: "",
        orgId: this.$globalData.userInfo.orgId,
        labels: "",
        orgDepth: "",
        buildingIds: "",
        unitId: "",
        houseId: "",
        haveHouse: "",
        userLabel: "",
        labelShortsAllCommunity: '',
        beginAge: "",
        endAge: "",
        sex: "",
        beginBirthday: "",
        endBirthday: "",
        beginMoveInDate: "",
        endMoveInDate: "",
        registryType: "",
        searchValue: "",
        nativePlace: '',
        rightIdNumber: '',
        labelCondition: '1'
      },
      totalNum: 0,
      loading: false,
      defaultDate: new Date(1900, 0, 1),
      show: {
        registryType: '户籍性质',
        beginBirthday: '最早',
        endBirthday: "至今",
        beginMoveInDate: '最早',
        endMoveInDate: "至今",
        nativePlace: '居民籍贯',
        gridName: '',
        age: '',
        sex: '',
        haveHouse: '',
        rightIdNumber: '',
        notInspect: ''
      },
      control: {
        showRegistryType: false,
        community: false,
        pullLoading: false,
        liveWhichCascader: false,
        communityLabelCascader: false,
        moreShow: false,
        searchBarHidden: false,
        showDate: false,
        beginBirthday: false,
        endBirthday: false,
        beginMoveInDate: false,
        endMoveInDate: false,
        area: false,
        haveMore: true,
        hasSubmit: false
      },
      activeIndex: true,
      selectList: {
        liveWhichTree: [],
        userCommunityLabelTree: [],
        gridsList: [],
        ageList: [{
          label: '18岁以下',
          value: '0,18',
        },
          {
            label: '18-29',
            value: '18,29',
          },
          {
            label: '30-39',
            value: '30,39',
          },
          {
            label: '40-49',
            value: '40,49',
          },
          {
            label: '50-59',
            value: '50,59',
          },
          {
            label: '60-69',
            value: '60,69',
          },
          {
            label: '70-79',
            value: '70,79',
          },
          {
            label: '80岁及以上',
            value: '80,0',
          },
        ],
        sexList: [{
          label: '男',
          value: '1',
        },
          {
            label: '女',
            value: '2',
          },
          {
            label: '所有',
            value: '',
          }],
        registryTypeList: [],
        haveHouseList: [{
          label: '所有',
          value: '1',
        },
          {
            label: '未入住',
            value: '0',
          }],
        rightIdNumberList: [{
          label: '错误身份证',
          value: "0",
        }, {
          label: '正确身份证',
          value: "1",
        }]
      },
      areaList:
          [{value: "11", label: '北京'}, {value: "12", label: '天津', status: 0}, {value: '13', label: '河北'}, {
            value: '14',
            label: '山西'
          }, {value: '15', label: '内蒙古'},
            {value: '21', label: '辽宁'}, {value: '22', label: '吉林'}, {value: '23', label: '黑龙江'}, {
            value: '31',
            label: '上海'
          }, {value: '32', label: '江苏'}, {value: '33', label: '浙江'},
            {value: '34', label: '安徽'}, {value: '35', label: '福建'}, {value: '36', label: '江西'}, {
            value: '37',
            label: '山东'
          }, {value: '41', label: '河南'}, {value: '42', label: '湖北'},
            {value: '43', label: '湖南'}, {value: '44', label: '广东'}, {value: '45', label: '广西'}, {
            value: '46',
            label: '海南'
          }, {value: '50', label: '重庆'}, {value: '51', label: '四川'},
            {value: '52', label: '贵州'}, {value: '53', label: '云南'}, {value: '54', label: '西藏'}, {
            value: '61',
            label: '陕西'
          }, {value: '62', label: '甘肃'}, {value: '63', label: '青海'},
            {value: '64', label: '宁夏'}, {value: '65', label: '新疆'}, {value: '71', label: '台湾'}, {
            value: '81',
            label: '香港'
          }, {value: '82', label: '澳门'}, {value: '91', label: '国外'}]
    }
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    that = this
    this.userName = this.$userName
    this.orgName = this.$orgName
    this.dataForm.notInspect = this.$globalData.notInspect
    this.init()
  },
  activated () {
    if (this.$route.query.searchInfo) {
      let globalSearchValue = this.$route.query
      globalSearchValue.searchInfo = JSON.parse(decodeURIComponent(globalSearchValue.searchInfo))
      if (globalSearchValue.searchVal) {
        globalSearchValue.searchVal = JSON.parse(decodeURIComponent(globalSearchValue.searchVal))
      }
      this.dataForm = {
        ...this.dataForm,
        ...globalSearchValue.searchInfo,
      }
      if (globalSearchValue.searchInfo.labels || globalSearchValue.searchVal.labels) {
        this.dataForm.labelCondition = 2
      }

      if (globalSearchValue.searchVal) {
        this.dataForm[globalSearchValue.searchVal.type] = globalSearchValue.searchVal.value
        if (globalSearchValue.searchVal.labels || globalSearchValue.searchVal.type == 'labels') {
          this.$refs.resLabel.selectList = [parseInt(globalSearchValue.searchVal.value || globalSearchValue.searchVal.labels)]
        }
        if (globalSearchValue.searchVal.age || globalSearchValue.searchVal.type == 'age') {
          let ageList = (globalSearchValue.searchVal.value || globalSearchValue.searchVal.age).split('^')
          this.dataForm['beginAge'] = ageList[0]
          this.dataForm['endAge'] = ageList[1]
          this.$refs.selectMore.dataForm.beginAge = ageList[0]
          this.$refs.selectMore.dataForm.endAge = ageList[1]
        }
        // if (globalSearchValue.searchVal.check || globalSearchValue.searchVal.type == 'allCheck') {
        //   this.dataForm.isNotChecked = globalSearchValue.searchVal.value
        // }
      }
    }
    this.dataForm.page = 0;
    this.dataList = [];
    this.getDataList();
  },
  methods: {
    getCommunity (userId) {
      this.userId = userId
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/allCommunity`),
        method: 'get',
        params: this.$http.adornParams({
          userId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.communityList = data.list
          if (this.communityList.length == 1) {
            this.onConfirmCommunity(this.communityList[0])
          } else {
            this.control.community = true
          }
        }
      })
    },
    Desensitization (value) {
      return desensitization(value)
    },
    getImg (path) {
      return  getImageStream(path)
    },
    init() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectList.liveWhichTree = [{label: "所有", value: "", depth: 0}].concat(data.liveWhichTree)
          this.selectList.liveWhichTree.map(item => {
            if (item.value) {
              this.$http({
                url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
                method: 'get',
                params: this.$http.adornParams({
                  communityId: item.value
                })
              }).then(({data}) => {
                if (data.code == 0) {
                  this.selectList.liveWhichTree.map((val, index) => {
                    if (item.value === val.value) {
                      this.selectList.liveWhichTree[index].children = this.selectList.liveWhichTree[index].children.concat(data.liveWhichTree)
                    }
                  })
                }
              })
            }
          })
        }
      })

      listComRightLabel(function (success) {
        that.selectList.userCommunityLabelTree = success
      })

      //所属网格
      this.$http({
        url: this.$http.adornUrl(`/wxapp/grid/info/listByUser`),
        method: 'get',
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.gridsList = data.grids
        }
      })

      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          that.selectList.registryTypeList = data.registryTypes
        }
      })
      // this.getDataList()

    },
    getDataList() {
      this.dataForm.page++
      if (!that.control.hasSubmit && that.dataForm.page == 1) {
        this.$toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        });
      }
      if (this.dataForm.notInspect !== '1') {
        this.dataForm.notInspect = '0'
      }
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/list`),
        method: 'post',
        params: this.$http.adornParams(that.dataForm)
      }).then(({data}) => {
        that.loading = false
        this.control.pullLoading = false
        if (!that.control.hasSubmit) {
          that.$toast.clear();
        }
        if (data.code == 0) {

          this.totalNum = data.page.totalCount
          let datas = data.page.list;
          if (datas.length > 0) {
            for (let i in datas) {
              let data = datas[i];
              if (data.labelShortsAllCommunity) {
                data["isPsychosis"] = data.labelShortsAllCommunity.search('精') == -1 ? false : true;
                data["isVisit"] = data.labelShortsAllCommunity.search('访') == -1 ? false : true;
                data['labelList'] = data.labelShortsAllCommunity.split(',')
              }
              let date = formatterDate(new Date(data.createTime));
              data.createTime = date
            }
            let list = that.dataForm.page == 1 ? datas : that.userResList.concat(datas);
            let haveMore = datas.length !== this.totalNum ? false : true;
            that.control.haveMore = haveMore
            that.activeIndex = false
            list.map(item => {
              if (item.idNumber.indexOf('UHT') > -1) {
                item.idNumber = '无证件'
              }
            })
            that.userResList = list
          } else if (datas == '' && that.dataForm.page != 1) {
            that.activeIndex = true
            that.control.haveMore = false
          } else {
            that.control.haveMore = false
            that.activeIndex = true
            that.userResList = []
          }
        } else {
          that.$toast.fail(data.msg)
          that.activeIndex = true
          that.control.haveMore = false;
        }
        that.control.hasSubmit = false;
      })
    },
    searchConfirm(val) {
      this.onPullDownRefresh()
    },
    searchCancel(val) {
      this.dataForm.searchValue = ''
      this.onPullDownRefresh()
    },
    beginBirthdayChange(value) {
      let d = new Date(this.dataForm.endBirthday)
      if (this.dataForm.endBirthday != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginBirthday = formatterDate(value)
      this.control.beginBirthday = false

    },
    endBirthdayChange(value) {
      let d = new Date(this.dataForm.beginBirthday)
      if (this.dataForm.beginBirthday != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endBirthday = formatterDate(value)
      this.control.endBirthday = false
    },
    beginMoveInDateChange(value) {
      let d = new Date(this.dataForm.endMoveInDate)
      if (this.dataForm.endMoveInDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.beginMoveInDate = formatterDate(value)
      this.control.beginMoveInDate = false
    },
    endMoveInDateChange(value) {
      let d = new Date(this.dataForm.beginMoveInDate)
      if (this.dataForm.beginMoveInDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('请选择正确的范围')
      }
      this.dataForm.endMoveInDate = formatterDate(value)
      this.control.endMoveInDate = false
    },
    onConfirmReg(e, index) {
      this.dataForm.registryType = e.value
      this.show.registryType = e.label
      this.onPullDownRefresh()
      this.control.showRegistryType = false
    },
    onCancelReg() {
      this.show.registryType = '户籍性质'
      this.dataForm.registryType = ""
      this.onPullDownRefresh()
      this.control.showRegistryType = false
    },
    onConfirmCommunity (e, index) {
      this.$dialog.confirm({
        message: `确认迁出${e.label}？`,
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
          method: 'get',
          params: this.$http.adornParams({
            id: parseInt(this.userId),
            community: e.value
          })
        }).then(({data}) => {
          if (data.code == 0) {
            that.$toast.success({
              message:'迁出成功',
              onOpened:()=>{
                setTimeout(()=>{
                  that.onPullDownRefresh();
                },1500)
              }
            })
            this.control.community = false
          } else {
            that.$toast.fail(data.msg)
          }
        })
      })
    },
    goVisit(item) {
      let houseId = null
      if (item.houseList) {
        houseId =  Number(item.houseList[1].id)
      }
      this.$router.push({path: '/visit-add', query: {houseId: houseId,community:item.community,communityName:item.communityName, userId: item.id, userName: item.name, fromPage: 'userRes'}})
    },
    onConfirm(e, index) {
      this.dataForm.nativePlace = e.value
      this.show.nativePlace = e.label
      this.onPullDownRefresh()
      this.control.area = false
    },
    onCancel() {
      this.show.nativePlace = '居民籍贯'
      this.dataForm.nativePlace = ""
      this.control.area = false
    },
    liveWhichFinish(e) {
      this.dataForm.orgId = this.$globalData.userInfo.orgId;
      this.dataForm.buildingIds = ""
      this.dataForm.unitId = ""
      this.dataForm.houseId = ""
      if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 0) {
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 1) {
        this.dataForm.orgId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
        this.dataForm.buildingIds = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 3) {
        this.dataForm.unitId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
        this.dataForm.houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value
      }

      // 'show.createUserArray': e.selectedOptions.map((n) => n.label).join('/'),
      this.dataForm.orgId = this.dataForm.orgId
      this.dataForm.buildingIds = this.dataForm.buildingIds
      this.dataForm.unitId = this.dataForm.unitId
      this.dataForm.houseId = this.dataForm.houseId
      // 'show.createUserName': e.selectedOptions[(e.selectedOptions.length - 1)].label
    },
    selectLabel (e) {
      const {selectList, labelCondition} = e
      this.dataForm.labels = selectList
      this.dataForm.labelCondition = labelCondition + ''
      this.control.communityLabelCascader = false
      this.onPullDownRefresh()
    },
    selectMore (e) {
      this.dataForm = {
        ...this.dataForm,
        ...e
      }
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    liveWhichClose(e) {
      this.dataForm.orgId = this.$globalData.userInfo.orgId;
      this.dataForm.buildingIds = ''
      this.dataForm.unitId = ''
      this.dataForm.houseId = ''
      this.control.liveWhichCascader = false
      this.onPullDownRefresh()
    },
    //标签信息改变
    communityLabelCascaderChange(e) {
      that.dataForm.labels = ""
      that.dataForm.labels = e.selectedOptions[(e.selectedOptions.length - 1)].value
    },
    communityLabelCascaderClose(e) {
      that.dataForm.labels = ""
      this.control.communityLabelCascader = false
      this.onPullDownRefresh()
    },
    /**
     * 页面相关事件处理函数--用户下拉动作
     */
    onPullDownRefresh(e) {
      this.dataForm.page = 0;
      this.getDataList()
    },
    //所有级联的关闭
    overlay() {
      this.onPullDownRefresh()
    },
    //出生日期重置按钮点击
    birthdayReset: function (e) {
      that.dataForm.beginBirthday = '';
      that.dataForm.endBirthday = '';
    },
    //出生日期时间筛选中点击确定
    birthdayTimeclick: function (e) {
      this.onPullDownRefresh()
    },
    //迁入时间重置按钮点击
    moveInReset: function (e) {
      that.dataForm.beginMoveInDate = '';
      that.dataForm.endMoveInDate = '';
    },
    //嵌入时间筛选中点击确定
    moveIntimeclick: function (e) {
      that.onPullDownRefresh()
    },
    //选择网格
    gridsFn(val) {
      this.show.gridName = val.label
      this.dataForm.gridId = val.value
    },
    ageFn(n) {
      this.show.age = n.label
      // this.dataForm.gridId = val.value
      const selected = n.value
      let ageArray = selected.split(",")
      this.dataForm.beginAge = ageArray[0];
      this.dataForm.endAge = ageArray[ageArray.length - 1];
    },
    sexFn(n) {
      this.show.sex = n.label
      this.dataForm.sex = n.value

    },
    registryFn(n) {
      this.show.registryType = n.label
      this.dataForm.registryType = n.value
    },
    haveHouseFn(n) {
      this.show.haveHouse = n.label
      this.dataForm.haveHouse = n.value
    },
    rightIdNumberFn(n) {
      this.show.rightIdNumber = n.label
      this.dataForm.rightIdNumber = n.value
    },
    moreClose() {

    },
    moreCancel() {
      //重置
      this.dataForm.gridId = ''
      this.dataForm.age = ''
      this.dataForm.sex = ''
      this.dataForm.registryType = ''
      this.dataForm.haveHouse = ''
      this.dataForm.rightIdNumber = ''
      this.show.gridName = ''
      this.show.age = ''
      this.show.sex = ''
      this.show.registryType = ''
      this.show.haveHouse = ''
      this.show.rightIdNumber = ''
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    moreConfirm() {
      this.control.moreShow = false
      this.onPullDownRefresh()
    },
    petition (id, name) {
      this.$router.push({path: '/petition-info', query: {userId: id, userName: name}})
    },
    cause (id, name) {
      this.$router.push({path: '/cause-info', query: {userId: id, userName: name}})
    },
    info(id) {
      this.$router.push({path: '/userRes-info', query: {userId: id}})
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    //打电话
    callPhone(mobile) {
      callPhone({
        phoneNum: mobile,//电话号码
      }).then(res => {
        console.log(res)
      }).catch(err => {})
    },
    idNumber(e) {
      return idNumber(e)
    },
    mobile(e) {
      return mobile(e)
    },
    /**
     * 页面上拉触底事件的处理函数
     */
    onReachBottom: function () {
      if (this.control.haveMore) {
        this.dataForm.page++;
        this.getDataList();
      }
    },
    // 新增居民
    addres() {
      this.$router.push({path: '/userRes-info'})
    }
  }
}

</script>

<style lang="scss" scoped>
.van-grid-item__content {
  padding: 5px 0 !important;
}
.content-text {
  width: 450px !important;
}
.content-img {
  width: 130px !important;
}
.van-popup {
  .van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
}

.imgg {
  width: 1.62rem;
  height: 1.62rem;
}

.buttons {
  font-size: 10px;
  border-radius: 0.12rem;
}

.tag-view {
  display: flex;
}

.wux {
  margin-left: 0.15rem;
}
.userFontSize{
  font-size: 30px;
}
.mini-font-size{
  font-size: 25px;
}
.van-col {
  p {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
